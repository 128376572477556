import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BotonArticulo } from '../interfaces/botonArticulo.interface';
import { BotonFuncion } from '../interfaces/botonFuncion.interface';
import { BotonGrupo } from '../interfaces/botonGrupo.interface';
import { BotonMesa } from '../interfaces/botonMesa.interface';
import { BotonParcela } from '../interfaces/botonParcela.interface';
import { Articulo } from '../interfaces/articulo.interface';

// let base_url = `${ environment.base_url }/TactilGruposBotones`;


@Injectable({
  providedIn: 'root'
})
export class TactilBotonesService {

  token:string = localStorage.getItem('tp-Token') || '';
  headers = new HttpHeaders({
    'Content-Type':'application/x-www-form-urlencoded',
    'Tp-Token': localStorage.getItem('tp-Token') || ''
  });

  constructor(private http: HttpClient) { }

  getAll(orden:string, modo: string = 'bar'){
    const base_url = `${ environment.base_url }/TactilBotones`;
    const url = `${ base_url }/getAll?order=${orden}&modo=${modo}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getCombo(codigoGrupo:string, orden:string, modo: string = 'bar'){
    const base_url = `${ environment.base_url }/TactilBotones`;
    const url = `${ base_url }/getAll?order=${orden}&codigoGrupo=${codigoGrupo}&modo=${modo}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getMesaId(id:number){
    const base_url = `${ environment.base_url }/TactilBotonesMesa`;
    const url = `${ base_url }/getMesaId/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getComboMesas(orden:string){
    const base_url = `${ environment.base_url }/TactilBotonesMesa`;
    const url = `${ base_url }/getAll?order=${orden}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getComboParcelas(orden:string, alternativos: boolean){
    const base_url = `${ environment.base_url }/TactilBotonesParcela`;
    const url = `${ base_url }/getAll?order=${orden}&alternativos=${alternativos}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getComboGrupo(orden:string, modo: string = 'bar'){
    const base_url = `${ environment.base_url }/TactilGruposBotones`;
    const url = `${ base_url }/getAll?&order=${orden}&modo=${modo}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getComboMenuRapido(orden:string){
    const base_url = `${ environment.base_url }/MenuRapido`;
    const url = `${ base_url }/getAll?&order=${orden}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getComboFunciones(orden:string){
    const base_url = `${ environment.base_url }/TactilBotonesFuncion`;
    const url = `${ base_url }/getAll?&order=${orden}`;
    // console.log(url);
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          // console.log(resp);
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarGrupo(data:BotonGrupo, id:number, modo: string = 'bar'){
    const base_url = `${ environment.base_url }/TactilGruposBotones`;
    const url = `${ base_url }/putBoton/${id}/${modo}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarBoton(data:BotonArticulo, id:number, modo: string = 'bar'){
    const base_url = `${ environment.base_url }/TactilBotones`;
    const url = `${ base_url }/putBoton/${id}/${modo}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarPreciosIdArticulo(data:Articulo[]){
    const base_url = `${ environment.base_url }/TactilBotones`;
    const url = `${ base_url }/actualizarPreciosIdArticulo`;
    // console.log(url);
    
    return this.http.post(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarFuncion(data:BotonFuncion, id:number){
    const base_url = `${ environment.base_url }/TactilBotonesFuncion`;
    const url = `${ base_url }/putBoton/${id}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarMesa(data:BotonMesa, id:number){
    const base_url = `${ environment.base_url }/TactilBotonesMesa`;
    const url = `${ base_url }/putBoton/${id}`;
    // console.log(url);
    
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  actualizarParcela(data:BotonParcela, alternativo: boolean){
    const base_url = `${ environment.base_url }/TactilBotonesParcela`;
    const url = (alternativo == true)? `${ base_url }/putBotonAlt/${data.id}`: `${ base_url }/putBoton/${data.id}`;

        
    return this.http.put(url, data, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, detalle:resp.detalle};
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getParcelaId(id:number){
    const base_url = `${ environment.base_url }/TactilBotonesParcela`;
    const url = `${ base_url }/getParcelaId/${id}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  moverBoton(idBotonInicial:number, idBotonFinal: number){
    const base_url = `${ environment.base_url }/TactilBotones`;
    const url = `${ base_url }/moverBoton/${idBotonInicial}/${idBotonFinal}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }

  getTarifa3(idArticulo:number){
    const base_url = `${ environment.base_url }/TactilBotones`;
    const url = `${ base_url }/getTarifa3/${idArticulo}`;
    
    return this.http.get(url, {headers: this.headers})
                    .pipe(
                      map(
                        (resp:any)=>{
                          const respuesta = {status:resp.status, totalPaginas: resp.totalPaginas, detalle:resp.detalle};
                          
                          return respuesta;
                        }
                      )
                    )
                    ;
  }
}
